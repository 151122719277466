import { css } from "@emotion/core";
import { mq } from "cssInJs";

export default {
  sliderAwardPanel: css(
    mq({
      marginTop: "20px",
      marginBottom: "20px",
      backgroundColor: "transparent",
      maxWidth: ["90%", "90%", "90%", "90%", "1200px", "1400px"],
      margin: "0 auto",
      "& .slick-prev:before, .slick-next:before": {
        color: "black",
      },
      "& .slick-next": {
        right: "-2%",
      },
      "& .slick-prev": {
        left: "-2%",
      },
    })
  ),
  sliderImageWrapper: {
    height: "100%",
  },
  sliderImageWrapper2: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "500px",
  },
  sliderImage: css({
    //width: 'auto !important',
    //height:'100% !important',
    //display: 'block !important',
    //margin: 'auto',
    //maxHeight: '180px'
  }),
};
