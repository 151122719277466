export function enquiryTypeModel() {
  const enquiryEmailMap = {
    "Tickets": "bookings@aaod.com.au",
    "Prep-A-Dino program": "laboratory@aaod.com.au",
    "Dig-A-Dino program": "laboratory@aaod.com.au",
    "Group visits": "bookings@aaod.com.au",
    "Press": "business@aaod.com.au",
    "Access (disability, inclusion, equity)": "bookings@aaod.com.au",
    "Corporate sponsorship or partnerships": "business@aaod.com.au",
    "Donations and legacy": "business@aaod.com.au",
    "Filming": "business@aaod.com.au",
    "Image licensing": "business@aaod.com.au",
    "Food and drinks at the Museum": "info@aaod.com.au",
    "Work experience or internships": "education@aaod.com.au",
    "Education programs": "education@aaod.com.au",
    "Museum Shop customer service": "business@aaod.com.au",
    "Website support": "business@aaod.com.au",
    "Lost property": "info@aaod.com.au",
    "Identifying a fossil": "laboratory@aaod.com.au",
    "Other": "info@aaod.com.au",
  };
  return enquiryEmailMap;
}
