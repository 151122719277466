import { fb, validators } from "../../../lib/form";

export function getContactModel(data) {
  let enquiryList = [
    "Tickets",
    "Prep-A-Dino program",
    "Dig-A-Dino program",
    "Group visits",
    "Press",
    "Access (disability, inclusion, equity)",
    "Corporate sponsorship or partnerships",
    "Donations and legacy",
    "Filming",
    "Image licensing",
    "Food and drinks at the Museum",
    "Work experience or internships",
    "Education programs",
    "Museum Shop customer service",
    "Website support",
    "Lost property",
    "Identifying a fossil",
    "Other",
  ].map((x) => {
    return { name: x, value: x };
  });
  enquiryList = [{ name: "Please select*", value: "" }, ...enquiryList];

  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Name", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Your email", type: "email" },
    ],
    confirmEmail: [
      data.confirmEmail || "",
      [validators.Required(), validators.Email()],
      { label: "Confirm your email", type: "email" },
    ],
    enquiryType: [
      data.enquiryType || "",
      [validators.Required()],
      {
        label: null,
        type: "select",
        options: enquiryList,
      },
    ],
    subject: [
      data.subject || "",
      [validators.Required()],
      { label: "A short description of your enquiry", type: "text" },
    ],
    message: [
      data.message || "",
      [validators.Required()],
      {
        label: "Please provide a complete description of your enquiry",
        type: "textarea",
      },
    ],
  });
  return model;
}
